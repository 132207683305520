.footer{
    height: 200px;
    width: 110%;
    background-color: rgb(0, 11, 20);
    color: white;
    margin: -5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  
  }

.coloumn{
  flex: 33%;
  
}


.socialpost{
  height: 20px;
  width: 20px;
  margin: 1%;
  transition: transform 330ms ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.219);
  padding: 2px;
  border-radius: 5px;
}

.socialpost:hover{
   transform: scale(1.1);
}

.heading{
  font-size: 1.17em;
  margin: 1em;
  font-weight: bold;
  text-align: left;
}

.subheads{
  float: left;
  text-align: left;
  width: 70%;
  margin: 0.1rem 0 0 1em;
}
