.menu-nav{
  background-color: rgb(26, 42, 56);
  color: rgb(255, 255, 255);
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 10;
  margin: -14% -5% 0% -5%;
  transform: translateX(-100px);
  transition: width 2s;
  padding-top: 15%; 
  font-size: 200%; 
}

.menu-nav.show-menu {
  width: 115%;
  
  
}

.toggle .close{
  z-index: 101;

}

.toggleopen{
  background-color: transparent;
  border: none;
  transition: width 0.2s, height 0.2s, transform 0.2s;
}
.toggleclose{
  background-color: transparent;
  border: none;
  transition: width 0.2s, height 0.2s, transform 0.2s;
}
.toggleopen:hover{
  transform: scale(1.1);

}
.toggleclose:hover{
  transform: scale(1.1);
}

.toogleimg{
  height: 30px;
  width: 30px;
}
.nav-img{
  height: 2em;
  margin: 0.5em;
}
