body{
  background-color: #10111b;
  color: white;
  padding: 5%;
  font-family: Sans-Serif;
  text-align: center;

}

.navbar{
  /* background-color: #363a42; */
    display: flex;
    justify-content: space-between;
    margin: -10% 20% 0% 20%;
    border-radius: 15px;
    border: 0.5px solid rgb(133, 133, 133);
    box-shadow: 0 5px 15px rgba(0,0,0,0.5);
    position: fixed;
    width: 50%;
    
    display: flex;
    background: rgba(42,43,56,.5);
    border: 0.1rem solid #2a2b37;
    -webkit-backdrop-filter: blur(104px);
    backdrop-filter: blur(104px);
    border-radius: 1rem;
    z-index: 11;
    padding: 1rem;
}

.navbar::before{
  content: ' ';
  background-attachment: fixed ;
  filter: blur(2px);
  background-color: rgba(233, 215, 215, 0.5);
  
}
.navelement{
  color: aqua;
  font-size: 150%;
  padding: 1%;
  margin: 1%;
  
}
.logotext{
  color: rgb(234, 250, 255);
  font-size: 130%;
  font-family: 'bonheur royale';
  word-wrap: break-word;
  margin: 1%;
  padding: 1%;

}

.headertext{
  font-size: 400%;
  text-align: left;
  margin: 10%;
  height: 3200px;
  font-family: 'montserrat';
  
}

.timeline{
  background-image: linear-gradient(#5918df 5%, rgb(0, 212, 166) 25%, rgb(255, 0, 0) 90%);
  left: 10%;
  position: absolute;
  top: 80%;
  width: 1px;
  z-index: 9;
}

.bullet::before{
  display: block;
    position: absolute;
    background-color: #1b191e;
    border: 2px solid #5918df;
    border-radius: 50%;
    content: "";
    height: 9px;
    left: -6px;
    top: 0;
    width: 9px;
    z-index: 10;

}

.textbox{
  margin: 10rem 0rem 0rem 4rem;
  background: linear-gradient(rgba(35, 37, 49, 0.5), rgba(27, 27, 43, 0.5));
  border: 0.2rem solid rgb(32, 34, 46);
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(54px);
  backdrop-filter: blur(54px);
  border-radius: 2rem;
  display: flex;
  align-items: center;
  padding: 2em;
  position: relative;
  width: 60vh;
  flex-direction: column;
  
    
}


.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}



.loader-container {
  width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: #10111b;
    animation: disappear 3s linear ;
    z-index: 1;
    margin: -5%;
    animation-timing-function: ease-in;
}

.quote{
  margin-top: 15%;
  font-size: 200%;
  font-family: 'zilla slab';
}

.spinner {
  /* width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
  background: rgba(0, 0, 0, 0.834) url('self-img.png'); */
}

.loader-image{
  width: 200px;
  height: 200px;
  border: 2px solid #203A43 ;
  border-radius: 100%;
  background: linear-gradient(-45deg, #0F2027, #203A43, #2c5364, #2c5364);
/* background-size: 300%;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
animation: animated_text 10s ease-in-out infinite;
-moz-animation: animated_text 10s ease-in-out infinite;
-webkit-animation: animated_text 10s ease-in-out infinite; */
}

@keyframes fade-in {
  0% {
      transform: scale(1);
  }
  100% {
      transform: scale(1.1);
      
  }
}
@keyframes disappear {
  0% {
      opacity: 0;
  }
  10%{
    opacity: 1;
  }
  70%{
    opacity: 1;
  }
  
  100% {
     opacity: 0;    
  }
  
}

.linearwipe{
  background: linear-gradient(-45deg, #6355a4, #6355a4, #e89a3e, #e89a3e);
background-size: 300%;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
animation: animated_text 10s ease-in-out infinite;
-moz-animation: animated_text 10s ease-in-out infinite;
-webkit-animation: animated_text 10s ease-in-out infinite;

  
}

@keyframes animated_text {
0% { background-position: 0px 50%; }
50% { background-position: 100% 50%; }
100% { background-position: 0px 50%; }
}


.boximg{
  height: 16em;
  width: 16em;
  border-radius: 30px;
  padding: 5%;
}

.project-text{
  padding: 7%;
}


.icon{
  height: 30px;
  transition: transform 330ms ease-in-out;


}
.icon:hover{
  transform: scale(1.1);

  
}