.object{
    margin: 2em 0 2em 0;
    transition: transform 330ms ease-in-out;
    cursor: -webkit-grab; cursor: grab;
}
.object:hover{
    transform: scale(1.1);
}

.link{
    text-decoration: none;
    color: white;
}